import React, {Fragment, useState} from 'react'
import { RouteContainer, H1, SlideInContainer, NavControlsLargeComponent } from "../utils";
import Wavesurfer from 'react-wavesurfer'

import './play-audio.component.style.sass'
import AudioControlsComponent from "./audio-controls/";

export default function PlayAudioComponent(props) {
    const {  audio: { binary }, user: { par_id }, sendAudio } = props;

    const nextPageOnClick = function (e) {
        sendAudio(binary, par_id)
    };

    const [playing, setPlaying] = useState(false);
    const [pos, setPos] = useState();

    return (
        <RouteContainer className='play-audio-component'>
            { binary !== null &&  binary !== 'recording' ? (
                <Fragment>
                    <SlideInContainer>
                        <H1>Legal! Adoramos sua história. Clique abaixo se quiser escutá-la.</H1>
                        <div className="player-container">
                            <AudioControlsComponent playing={playing} setPlaying={setPlaying}/>
                            <Wavesurfer
                                className='wave-container'
                                audioFile={binary}
                                pos={pos}
                                onPosChange={(e) => setPos(e.originalArgs[0])}
                                onFinish={() => {
                                    setPlaying(false);
                                }}
                                playing={playing}
                            />
                        </div>
                    </SlideInContainer>
                    <SlideInContainer>
                        <NavControlsLargeComponent prevPage='/historia/novo/audio'  nextPageOnClick={nextPageOnClick} nextPage='/historia/concluir'/>
                    </SlideInContainer>
                </Fragment>
            ) : undefined }
        </RouteContainer>
    )
}
