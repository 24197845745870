import { defaultState } from "../utils";
import { CHANGE_USER_DATA, TOGGLE_AGREEMENT, SET_AUDIO_BLOB, SET_VIDEO_BLOB, ADD_FILE, CHANGE_TEXTAREA, SEND_PAR_DATA, SEND_VIDEO, SEND_AUDIO, SEND_FILE, SET_DEFAULT_STATE } from '../actions'

export default (state = defaultState, action) => {
    console.log(action);
    switch (action.type) {
        case SET_DEFAULT_STATE: {
            return {
                ...defaultState
            }
        }
        case `${SEND_FILE}_PENDING`: {
            return {
                ...state,
                fetching: true,
                error: false
            }
        }
        case `${SEND_FILE}_FULFILLED`: {
            return {
                ...state,
                fetching: false,
                error: false
            }
        }
        case `${SEND_FILE}_REJECTED`: {
            return {
                ...state,
                fetching: false,
                error: true
            }
        }
        case `${SEND_AUDIO}_PENDING`: {
            return {
                ...state,
                fetching: true,
                error: false
            }
        }
        case `${SEND_AUDIO}_FULFILLED`: {
            return {
                ...state,
                fetching: false,
                error: false
            }
        }
        case `${SEND_AUDIO}_REJECTED`: {
            return {
                ...state,
                fetching: false,
                error: true
            }
        }
        case `${SEND_VIDEO}_PENDING`: {
            return {
                ...state,
                fetching: true,
                error: false
            }
        }
        case `${SEND_VIDEO}_FULFILLED`: {
            return {
                ...state,
                fetching: false,
                error: false
            }
        }
        case `${SEND_VIDEO}_REJECTED`: {
            return {
                ...state,
                fetching: false,
                error: true
            }
        }
        case `${SEND_PAR_DATA}_PENDING`: {
            return {
                ...state,
                user: {
                    ...state.user,
                    fetching: true
                }
            }
        }
        case `${SEND_PAR_DATA}_FULFILLED`: {
            const { payload: { data }} = action;
            if(data.error === undefined){
                if(data.success === true) {
                    return {
                        ...state,
                        user: {
                            ...state.user,
                            fetching: false,
                            fetched: true,
                            error: false,
                            par_id: data.db_query_id,
                        }
                    }
                } else {
                    break;
                }
            } else {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        fetching: false,
                        fetched: true,
                        error: true
                    }
                }
            }
        }
        case `${SEND_PAR_DATA}_REJECTED`: {
            return {
                ...state,
                user: {
                    ...state.user,
                    fetching: false,
                    fetched: true,
                    error: true
                }
            }
        }
        case CHANGE_TEXTAREA: {
            const { value } = action.payload;
            return {
                ...state,
                text: value
            }
        }
        case ADD_FILE: {
            const { file } = action.payload;
            if (file !== undefined && file.name !== undefined){
                const splitName = file.name.split('.');
                const extension = splitName[splitName.length - 1];
                const supportedExtensions = ['jpeg', 'jpg', 'png', 'doc', 'docx', 'gif', 'wav', 'mp3', 'mp4', 'avi', 'mkv'];
                if(supportedExtensions.includes(extension))
                    return {
                        ...state,
                        errorFile: false,
                        files: [
                            file
                        ]
                    };
                else {
                    return {
                        ...state,
                        errorFile: true,
                    }
                }
            } else {
                return {
                    ...state
                }
            }
        }
        case SET_VIDEO_BLOB: {
            const { binary } = action.payload;
            return {
                ...state,
                video: {
                    ...state.audio,
                    binary
                }
            }
        }
        case SET_AUDIO_BLOB: {
            const { binary } = action.payload;
            return {
                ...state,
                    audio: {
                        ...state.audio,
                        binary
                    }
            }
        }
        case TOGGLE_AGREEMENT: {
            return {
                ...state,
                agreed: !state.agreed
            }
        }
        case CHANGE_USER_DATA: {
            let { key, value } = action.payload;
            switch (key){
                default: {
                    break;
                }
                case 'age': {
                    value = value.length > 2 ? state.user.age : !isNaN(value) ? value : state.user.age;
                    break;
                }
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    [key]: value
                }
            };
        }
        default:
            return state;
    }
};
