import Axios from 'axios'

export const    CHANGE_USER_DATA = 'CHANGE_USER_DATA',
                TOGGLE_AGREEMENT = 'TOGGLE_AGREEMENT',
                SET_AUDIO_BLOB = 'SET_AUDIO_BLOB',
                SET_VIDEO_BLOB = 'SET_VIDEO_BLOB',
                ADD_FILE = 'ADD_FILE',
                CHANGE_TEXTAREA = 'CHANGE_TEXTAREA',
                SEND_PAR_DATA = 'SEND_PAR_DATA',
                SEND_VIDEO = 'SEND_VIDEO',
                SEND_AUDIO = 'SEND_AUDIO',
                SEND_TEXT = 'SEND_TEXT',
                SEND_FILE = 'SEND_FILE',
                SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';


//define objeto actions
const actions = {
    setDefaultState: () => ({
        type: SET_DEFAULT_STATE
    }),
    sendText: (text, id_user) => {
        const data = {
            text
        };

        return {
            type: SEND_TEXT,
            payload: new Axios({
                method: 'post',
                url: `//portalatlas.mediacenterbr.com.br/sia/sistema/aplicativos/historias_de_elevador/ws/new/text/${id_user}`,
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                data
            })
        }
    },
    sendFile: (file, id_user) => {
        const data = new FormData();
       data.append('file', file);

        return {
            type: SEND_FILE,
            payload: new Axios({
                method: 'post',
                url: `//portalatlas.mediacenterbr.com.br/sia/sistema/aplicativos/historias_de_elevador/ws/new/file/${id_user}`,
                data,
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    'Access-Control-Allow-Origin': '*'
                },
            })
        }
    },
    sendAudio: (audio, id_user) => {
            const data = new FormData();
            data.append('audio', audio);

            return {
                type: SEND_AUDIO,
                payload: new Axios({
                    method: 'post',
                    url: `//portalatlas.mediacenterbr.com.br/sia/sistema/aplicativos/historias_de_elevador/ws/new/audio/${id_user}`,
                    data,
                    headers: {
                        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                        'Access-Control-Allow-Origin': '*'
                    },
                })
            }
    },
    sendVideo: (video, id_user) => {
        const data = new FormData();
        data.append('video', video);

        return {
            type: SEND_VIDEO,
            payload: new Axios({
                method: 'post',
                url: `//portalatlas.mediacenterbr.com.br/sia/sistema/aplicativos/historias_de_elevador/ws/new/video/${id_user}`,
                data,
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    'Access-Control-Allow-Origin': '*'
                },
            })
        }
    },
    sendParData: (name, age, email) => {
        const data = {
            name, age, email
        };

        return {
            type: SEND_PAR_DATA,
            payload: new Axios({
                method: 'post',
                url: '//portalatlas.mediacenterbr.com.br/sia/sistema/aplicativos/historias_de_elevador/ws/new/user',
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                data
            })
        }
    },
    changeTextarea: value => ({
        type: CHANGE_TEXTAREA,
        payload: {
            value
        }
    }),
    addFile: file => ({
        type: ADD_FILE,
        payload: {
            file
        }
    }),
    setVideoBlob: binary => ({
        type: SET_VIDEO_BLOB,
        payload: {
            binary
        }
    }),
    setAudioBlob: binary => ({
        type: SET_AUDIO_BLOB,
        payload: {
            binary
        }
    }),
    toggleAgreement: () => ({
        type: TOGGLE_AGREEMENT
    }),
    changeUserData: (key, value) => ({
        type: CHANGE_USER_DATA,
        payload: {
            key,
            value
        }
    })
};

export default actions;
