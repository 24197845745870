import React, {Component} from 'react';
import actions from "../../actions";
import {Redirect} from 'react-router-dom'
import { connect } from "react-redux";
import { ConcluirComponent } from '../../components'

class ConcluirContainer extends Component {
    render() {
        return this.props.agreed === true && this.props.user.name !== '' && this.props.user.age !== '' && this.props.user.email !== '' ?
            <ConcluirComponent {...this.props} /> :
            <Redirect to='/inicio'/>;
    }
}

const mapDispatchToProps = dispatch => ({
    setDefaultState: () => {
        dispatch(actions.setDefaultState());
    }
});

const mapStateToProps = state => ({
    agreed: state.agreed,
    user: state.user
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConcluirContainer);
