import React from 'react'
import styled from 'styled-components'

export const H1 = styled.h1`
    padding: 0 0 45px;
    margin: auto;   
    font-family: Amarillo, sans-serif;
    font-size: 18px;
    line-height: 2;
    color: #816437;
    width: fit-content;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const StyledCheckbox = styled.div`
    cursor: pointer;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: ${props => (props.checked ? '#99211c' : 'transparent')}
    border-radius: 3px;
    box-shadow: 0 0 0 2px #99211c;
    transition: all 150ms;
  
    
    ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
    }
`;

export const Checkbox = ({ className, checked, onChange, ...props }) => (
    <CheckboxContainer className={className}>
        <HiddenCheckbox checked={ checked } onChange={onChange} {...props} />
        <StyledCheckbox checked={ checked }>
            <Icon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
            </Icon>
        </StyledCheckbox>
    </CheckboxContainer>
);

