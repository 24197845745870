import React from 'react'
import './concluir.component.style.sass'

import { RouteContainer, H1, SlideInContainer } from '../'

export default function ConcluirComponent(props){
    const { setDefaultState} = props;

    const handleOnClick = function(){
        setDefaultState();
    };

    return (
        <RouteContainer className="concluir-component-container">
            <SlideInContainer>
                <H1>
                    História enviada com sucesso.
                </H1>
                <p className="p-concluir">
                    Sua história já chegou até a gente. Agora, vamos analisar todas as histórias
                    que recebemos e escolheremos XX para ilustrá-las. Aguarde e torça para a sua ser escolhida.
                    Em breve, entraremos em contato.
                    <br/><br/>
                    <strong>
                        OBRIGADO!
                    </strong>
                </p>
            </SlideInContainer>
            <SlideInContainer>
                <button className='button-concluir' onClick={handleOnClick}>
                    Concluir
                </button>
            </SlideInContainer>
        </RouteContainer>
    )
}
