import React from "react";
import {Link} from "react-router-dom";
import { SlideInContainer, ButtonComponent } from "../";

import './nav-controls.component.styles.sass'

export default function NavControlsComponent(props){
    const { prevPage, nextPage, prevPageOnClick, nextPageOnClick } = props;
    return (
        <SlideInContainer>
            <div className='nav-controls-container'>
                <Link to={prevPage || ''} onClick={ prevPage === null ? e => e.preventDefault() : prevPageOnClick ? prevPageOnClick : undefined }>
                    <ButtonComponent disabled={ prevPage === null } text='Voltar' direction='rtl' color='red'/>
                </Link>
                <Link to={nextPage || ''} onClick={ nextPage === null ? e => e.preventDefault() : nextPageOnClick ? nextPageOnClick : undefined }>
                    <ButtonComponent disabled={ nextPage === null } text='Avançar' direction='ltr' color='green'/>
                </Link>
            </div>
        </SlideInContainer>

    )
}
