import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from './button'
import RecordButton from './record-button'
import StopButton from './stop-button'
import Timer from './timer'
import Countdown from './countdown'

const ActionsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 80px;
`

const Actions = ({
                     isVideoInputSupported,
                     isInlineRecordingSupported,
                     thereWasAnError,
                     isRecording,
                     isCameraOn,
                     streamIsReady,
                     isConnecting,
                     isRunningCountdown,
                     countdownTime,
                     timeLimit,
                     isReplayingVideo,
                     useVideoInput,

                     onTurnOnCamera,
                     onTurnOffCamera,
                     onOpenVideoInput,
                     onStartRecording,
                     onStopRecording,
                     onStopReplaying,
                     onConfirm
                 }) => {
    const renderContent = () => {
        const shouldUseVideoInput =
            !isInlineRecordingSupported && isVideoInputSupported

        if (
            (!isInlineRecordingSupported && !isVideoInputSupported) ||
            thereWasAnError ||
            isConnecting ||
            isRunningCountdown
        ) {
            return null
        }

        if (isReplayingVideo) {
            return (
                <Button onClick={onStopReplaying} data-qa='start-replaying'>
                    Gravar denovo!
                </Button>
            )
        }

        if (isRecording) {
            return <StopButton onClick={onStopRecording} data-qa='stop-recording' />
        }

        if (isCameraOn && streamIsReady) {
            return (
                <RecordButton onClick={onStartRecording} data-qa='start-recording' />
            )
        }

        if (useVideoInput) {
            return (
                <Button onClick={onOpenVideoInput} data-qa='open-input'>
                    Suba um video.
                </Button>
            )
        }

        return shouldUseVideoInput ? (
            <Button onClick={onOpenVideoInput} data-qa='open-input'>
                Grave um video.
            </Button>
        ) : (
            <Button onClick={onTurnOnCamera} data-qa='turn-on-camera'>
                Ligue a minha câmera!
            </Button>
        )
    }

    return (
        <div>
            {isRecording && <Timer timeLimit={timeLimit} />}
            {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
            <ActionsWrapper>{renderContent()}</ActionsWrapper>
        </div>
    )
}

Actions.propTypes = {
    isVideoInputSupported: PropTypes.bool,
    isInlineRecordingSupported: PropTypes.bool,
    thereWasAnError: PropTypes.bool,
    isRecording: PropTypes.bool,
    isCameraOn: PropTypes.bool,
    streamIsReady: PropTypes.bool,
    isConnecting: PropTypes.bool,
    isRunningCountdown: PropTypes.bool,
    countdownTime: PropTypes.number,
    timeLimit: PropTypes.number,
    isReplayingVideo: PropTypes.bool,
    useVideoInput: PropTypes.bool,

    onTurnOnCamera: PropTypes.func,
    onTurnOffCamera: PropTypes.func,
    onOpenVideoInput: PropTypes.func,
    onStartRecording: PropTypes.func,
    onStopRecording: PropTypes.func,
    onStopReplaying: PropTypes.func,
    onConfirm: PropTypes.func
}

export default Actions
