import React from 'react'
import {H1, SlideInContainer, Checkbox} from "../";
import {Scrollbars} from 'react-custom-scrollbars';

import './regulamento.component.style.sass'
import { NavControlsComponent, RouteContainer } from "../utils";

export default function RegulamentoComponent(props) {
    const {regulamento, agreed, toggleAgreement} = props;
    return (
        <RouteContainer className='regulamento-component'>
            <SlideInContainer>
                <H1>
                    Regulamento
                </H1>
            </SlideInContainer>
            <SlideInContainer>
                <div className='p-regulamento-container'>
                    <Scrollbars style={{width: '100%', height: '100%'}}>
                        <p className='p-regulamento'>
                            {regulamento}
                        </p>
                    </Scrollbars>
                </div>
            </SlideInContainer>
            <SlideInContainer>
                <div className='checkbox-agreement'>
                    <label>
                        <div className='checkbox-container'>
                                <Checkbox name='checkbox-agreement' onChange={toggleAgreement} checked={agreed}  />

                        </div>
                        <div className='checkbox-text'>
                            Declaro que li e concordo com o Regulamento.
                        </div>
                    </label>
                </div>
            </SlideInContainer>
            <div className='regulamento-nav-controls-container'>
                <NavControlsComponent prevPage='/inicio' nextPage={ agreed === true ? '/historia/dados' : null }/>
            </div>
        </RouteContainer>
    )
}
