import React from 'react'
import './input.component.style.sass'

export default function InputComponent(props) {
    const { placeholder, type, onChange, name, value } = props;

    return (
        <div className='input-component-container'>
          <input className='input-component' placeholder={placeholder} type={type} onChange={onChange} name={name} value={value} />
        </div>
    )
};


