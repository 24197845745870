import React from 'react'
import InputComponent from "../../utils/input";

export default function FormComponent(props) {
    const { changeUserData, user: { name, age, email } } = props;

    function onChange(e){
        let { target: { name, value } } = e;
        changeUserData(name, value)
    }

    return (
        <form>
            <InputComponent placeholder='Nome Completo' onChange={ onChange } value={ name || '' } type='text' name='name' />
            <InputComponent placeholder='Idade' onChange={ onChange } value={ age || '' }  type='number' name='age' />
            <InputComponent placeholder='E-mail' onChange={ onChange } value = { email || '' }  type='email' name='email' />
        </form>
    )
}
