import React, { useRef, useEffect, } from 'react'
import { NavControlsLargeComponent, H1, SlideInContainer, RouteContainer } from "../utils";
import { Redirect } from 'react-router-dom'

import './play-video.component.style.sass'


export default function PlayVideoComponent(props) {
    const { video: { binary}, sendVideo, user: { par_id }} = props;
    const video = useRef();

    const nextPageOnClick = function (e) {
        sendVideo(binary, par_id)
    };

    useEffect(() => {
        if(binary !== null){
            const reader = new FileReader();
            reader.onload = e =>
                video.current.src = e.target.result;
            reader.readAsDataURL(binary);
        }
    });
    return binary !== null
        ? (
            <RouteContainer style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}  className='play-video-component'>
                <SlideInContainer>
                    <H1>
                        Seu vídeo ficou incrível! Clique na imagem para assisti-lo.
                    </H1>
                </SlideInContainer>
                <SlideInContainer className='video-player-container' style={{marginBottom: 40}}>
                    <video width='100%' autoPlay controls ref={video}/>
                </SlideInContainer>
                <SlideInContainer>
                    <NavControlsLargeComponent prevPage='/historia/novo/video' nextPage='/historia/concluir' nextPageOnClick={nextPageOnClick}/>
                </SlideInContainer>
            </RouteContainer>
        ) : (
            <Redirect to='/historia/novo/video'/>
        )
}
