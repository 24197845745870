import React from 'react'
import { MdPlayArrow, MdPause } from 'react-icons/md'

import './audio-controls.component.style.sass'

export default function AudioControlsComponent(props) {
    const { playing, setPlaying } = props;
    return (
        <div className='controls-container'>
            <div className='button-container'>
                <button
                    onClick={e => {
                        setPlaying(!playing)
                    }}
                >
                    { playing ?
                        (
                            <MdPause/>
                        ) : (
                            <MdPlayArrow/>
                        )
                    }
                </button>
            </div>
        </div>
    )
}
