import posed from "react-pose";

const RouteContainer = posed.div({
    enter: {
        staggerChildren: 50
    }
});

const SlideInContainer = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: 50, opacity: 0 }
});

const FadeContainer = posed.div({
    enter: { opacity: 1 },
    exit: { opacity: 0 }
});

export {
    RouteContainer, SlideInContainer, FadeContainer
}
