import React from 'react'
import './button.component.style.sass'

export default function ButtonComponent(props) {
    const { onClick, text, color, direction, style, disabled } = props;
    return (
        <div className='button-component-container'>
            <button
                style={ style }
                className={ `button-component ${ color === 'red' ? direction === 'ltr' ? 'ltr-red' : 'rtl-red' : direction === 'ltr' ? 'ltr-green' : 'rtl-green' }` }
                onClick={ onClick }
                disabled={ disabled }
            >
                { text }
            </button>
        </div>
    )
};


