import React, {Component} from 'react';
//import actions from "../../actions";
import { connect } from "react-redux";
import {Redirect} from 'react-router-dom'
import { PlayAudioComponent } from '../../components'
import actions from "../../actions";

class PlayAudioContainer extends Component {
    render() {
        return this.props.agreed === true && this.props.user.name !== '' && this.props.user.age !== '' && this.props.user.email !== '' ?
            <PlayAudioComponent user={this.props.user} audio={this.props.audio} sendAudio={this.props.sendAudio} /> :
            <Redirect to='/inicio'/>;
    }
}

const mapDispatchToProps = dispatch => ({
    sendAudio: (audio, id_user) => {
        dispatch(actions.sendAudio(audio, id_user));
    }
});

const mapStateToProps = state => ({
    audio: state.audio,
    agreed: state.agreed,
    user: state.user
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayAudioContainer);
