import React, { useState, useEffect } from 'react';
import ReactStopwatch from 'react-stopwatch';

import ButtonComponent from './button'
import InputComponent from './input'
import LogoComponent from './logo'
import NavControlsComponent from './nav-controls'
import NavControlsLargeComponent from './nav-controls-large'
import FileUploadComponent from './file-upload'

import { FadeContainer, SlideInContainer, RouteContainer } from "./posed.components";
import { H1, Checkbox } from "./styled.components";

const Stopwatch = () => (
    <ReactStopwatch
        seconds={0}
        minutes={0}
        hours={0}
        render={({ formatted }) => {
            return (
                <div>
                    { formatted }
                </div>
            );
        }}
    />
);

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const useWindowDimensions = function() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};


export {
    useWindowDimensions,
    Stopwatch,
    FadeContainer,
    SlideInContainer,
    RouteContainer,
    LogoComponent,
    ButtonComponent,
    InputComponent,
    NavControlsComponent,
    FileUploadComponent,
    NavControlsLargeComponent,
    H1,
    Checkbox
}
