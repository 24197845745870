import React from 'react'
import { RecordVideoContainer }  from "../../../containers";

export default function RecordVideoPage(props) {
    return (
        <div style={ { flexGrow: 1, display: 'flex', flexDirection: 'column' } } className='record-video--page-container'>
            <RecordVideoContainer/>
        </div>
    )
}
