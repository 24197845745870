import React from 'react'
import { AudioContainer } from "../../../containers";

export default function RecordAudioPage(props) {
    return (
        <div style={ { flexGrow: 1, display: 'flex', flexDirection: 'column' } } className='audio-page-container'>
            <AudioContainer/>
        </div>
    )
}
