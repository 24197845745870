// import funcionais
import React, {Component} from 'react';
import {connect} from "react-redux";

//import actions
//import actions from '../../actions'
import PrimaryLayout from "../../layouts/";

class AppContainer extends Component {
    render() {
        return (
            <div className="app-container">
                <PrimaryLayout error={this.props.error} fetching={this.props.fetching} state={this.props.state}/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state => ({
    state: state,
    error: state.error,
    fetching: state.fetching
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppContainer);
