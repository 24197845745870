import React, {Component} from 'react'
//import actions from "../../actions"
import { connect } from "react-redux"
import { HeroComponent } from "../../components/"

class HomeContainer extends Component {
    render() {
        return (
            <div className="home-container">
                <HeroComponent/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeContainer);
