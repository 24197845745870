import React, {Component} from 'react';
import actions from "../../actions";
import { connect } from "react-redux";
import {Redirect} from 'react-router-dom'
import { PlayVideoComponent } from '../../components'

class PlayVideoContainer extends Component {
    render() {
        return this.props.agreed === true && this.props.user.name !== '' && this.props.user.age !== '' && this.props.user.email !== '' ?
            <PlayVideoComponent {...this.props}/> :
            <Redirect to='/inicio'/>;
    }
}

const mapDispatchToProps = dispatch => ({
    sendVideo: (video, id_user) => {
        dispatch(actions.sendVideo(video, id_user));
    }
});

const mapStateToProps = state => ({
    video: state.video,
    agreed: state.agreed,
    user: state.user
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayVideoContainer);
