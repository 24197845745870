import HistoriaPage from './historia.page'
import RegulamentoPage from './regulamento'
import DadosPage from './dados'
import MidiaPage from './midia'
import RecordAudioPage from './record-audio'
import PlayAudioPage from './play-audio'
import RecordVideoPage from './record-video'
import PlayVideoPage from './play-video'
import ArquivoPage from './arquivo'
import EscrevaPage from './escreva'
import ConcluirPage from './concluir'

export {
    DadosPage, RegulamentoPage, HistoriaPage, MidiaPage, RecordAudioPage, PlayAudioPage, RecordVideoPage, PlayVideoPage, ArquivoPage, EscrevaPage, ConcluirPage
}

export default HistoriaPage
