import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { HistoriaLayout } from "../../layouts/";

class HistoriaPage extends Component {
    render() {
        return (
            <div style={{minHeight: 'calc(100% - 60px)'}} className="historia-page">
                <HistoriaLayout state={this.props.state}/>
            </div>
        )
    }
}

export default withRouter(HistoriaPage);
