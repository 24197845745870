import AppContainer from './app'
import MidiaContainer from './midia'
import RegulamentoContainer from './regulamento'
import DadosContainer from './dados'
import AudioContainer from './record-audio'
import HomeContainer from './home'
import PlayAudioContainer from './play-audio'
import RecordVideoContainer from './record-video'
import PlayVideoContainer from './play-video'
import ArquivoContainer from './arquivo'
import EscrevaContainer from './escreva'
import ConcluirContainer from './concluir'

export {  HomeContainer, DadosContainer, RegulamentoContainer, MidiaContainer, AudioContainer, PlayAudioContainer, RecordVideoContainer, PlayVideoContainer, ArquivoContainer, EscrevaContainer, ConcluirContainer }

export default AppContainer
