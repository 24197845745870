import React from 'react'
import { PlayVideoContainer } from "../../../containers";

export default function PlayVideoPage(props) {
    return (
        <div style={ { flexGrow: 1, display: 'flex', flexDirection: 'column' } } className='play-video-page'>
            <PlayVideoContainer/>
        </div>
    )
}
