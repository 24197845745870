import React from 'react'
import { ArquivoContainer } from "../../../containers";

export default function ArquivoPage(props) {
    return (
        <div style={ { flexGrow: 1, display: 'flex', flexDirection: 'column' } } className='audio-page-container'>
            <ArquivoContainer/>
        </div>
    )
}
