import React from 'react'
import {RouteContainer, H1, SlideInContainer, FileUploadComponent, NavControlsComponent} from "../utils";

import './escreva.component.style.sass'

export default function EscrevaComponent(props) {
    const { files, errorFile, addFile, changeTextarea, text, sendText, sendFile, user: { par_id }} = props;

    const handleFile = function(e){
        addFile(e.target.files[0])
    };

    const handleTextareaChange = function (e) {
        changeTextarea(e.target.value)
    };

    const nextPageOnClick = function (e) {
        sendText(text, par_id);
        sendFile(files[0], par_id)
    };


    return (
        <RouteContainer className="escreva-component-container">
            <SlideInContainer>
                <H1 style={{paddingBottom: 20}}>
                    Agora, nos escreva sua história.
                </H1>
                <p className='p-escreva'>
                    Estamos curiosos para saber a sua. Escreva no campo abaixo contando sua história de elevador. <br/>
                    Se preferir, pode também fazer o upload de um arquivo para complementar sua história.
                </p>
                <div className="textarea-container">
                    <textarea value={text} onChange={handleTextareaChange} className='textarea-input' rows={5} />
                </div>
                <FileUploadComponent
                    files={files}
                    errorFile={errorFile}
                    handleFile={handleFile}
                />
            </SlideInContainer>
            <SlideInContainer>
                <NavControlsComponent prevPage={files[0] !== undefined || text !== null ? null : '/historia/midia'} nextPage='/historia/concluir' nextPageOnClick={nextPageOnClick} />
            </SlideInContainer>
        </RouteContainer>
    )
};
