import React from 'react'
import { RouteContainer, H1, SlideInContainer, NavControlsComponent, FileUploadComponent }  from '../'

import './arquivo.component.style.sass'

export default function ArquivoComponent(props){
    const { addFile, files, errorFile, sendFile, user: { par_id } } = props;

    const handleFile = function (e) {
        addFile(e.target.files[0])
    };

    const nextPageOnClick = function (e) {
        sendFile(files[0], par_id)
    };

    return (
        <RouteContainer className='arquivo-component'>
            <SlideInContainer>
                <H1>
                    Envie pra gente a sua história.
                </H1>
                <FileUploadComponent files={files} errorFile={errorFile} handleFile={handleFile} />
            </SlideInContainer>
            <SlideInContainer>
                <NavControlsComponent prevPage={files[0] !== undefined ? null : '/historia/midia'} nextPage={files[0] !== undefined ? '/historia/concluir' : null } nextPageOnClick={nextPageOnClick} />
            </SlideInContainer>
        </RouteContainer>
    )
};
