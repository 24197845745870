import React from 'react'
import { PlayAudioContainer } from "../../../containers";

export default function PlayAudioPage(props) {
    return (
        <div style={ { flexGrow: 1, display: 'flex', flexDirection: 'column' } } className='audio-page-container'>
            <PlayAudioContainer/>
        </div>
    )
}
