import React, { useRef } from 'react'
import WaveSurfer from 'wavesurfer.js'

import './audio-visualizer.component.style.sass'

export default function AudioVisualizer(props) {
    const { stream, recording } = props;

    const waveForm = useRef();

    if(waveForm.current !== undefined && waveForm.current.innerHTML === ""){
        const wave = WaveSurfer.create({
            container: waveForm.current,
            height: 150,
            waveColor: '#666666'
        });
        const micContext = wave.backend.getAudioContext();
        const mediaStreamSource = micContext.createMediaStreamSource(stream);
        const gainNode = micContext.createGain();
        const levelChecker = micContext.createScriptProcessor(4096, 1, 1);

        mediaStreamSource.connect(gainNode);
        gainNode.gain.value = 3;

        mediaStreamSource.connect(levelChecker);
        levelChecker.connect(micContext.destination);


        levelChecker.onaudioprocess = function (event) {
            wave.empty();
            wave.loadDecodedBuffer(event.inputBuffer);
        };
    }

    return (
        <div className="audio-visualizer">
                <div
                    style={{
                        display: recording ? "block" : "none"
                    }}
                    ref={waveForm}
                />
                <div
                    style={{
                        display: !recording ? "flex" : "none",
                        height: 150,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            border: 'none',
                            borderBottom: '1px solid #555',
                            height: 1
                        }}
                    />
                </div>
        </div>
    )
}
