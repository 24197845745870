import React from 'react'
import { Link } from 'react-router-dom'

import './cta.component.style.sass'

export default function CtaComponent(props) {
    const { text, icon, to } = props;
    return (
        <Link to={to}>
            <div className='cta-component-parent'>
                <div className="cta-component">
                    <div className="cta-content">
                        <div className="icon-container">
                            { icon }
                        </div>
                        <div className="text-container">
                            { text }
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}
