import React from 'react'
import { ConcluirContainer } from "../../../containers";

export default function ConcluirPage(props) {
    return (
        <div style={ { flexGrow: 1, display: 'flex', flexDirection: 'column' } } className='concluir-page-container'>
            <ConcluirContainer/>
        </div>
    )
}
