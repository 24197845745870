import React from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import {
    DadosPage,
    MidiaPage,
    RegulamentoPage,
    RecordAudioPage,
    PlayAudioPage,
    RecordVideoPage,
    PlayVideoPage,
    ArquivoPage,
    EscrevaPage,
    ConcluirPage
} from "../../pages";
import LogoComponent from "../../components/utils/logo";

import './history.layout.style.sass'

const HistoriaLayout = ({ match: { path } }) => {
    return (
        <div className="historia-layout">
            <LogoComponent/>
            <Switch>
                <Route path={`${path}/regulamento`} render={() => <RegulamentoPage/>} />
                <Route path={`${path}/dados`} render={() => <DadosPage/>} />
                <Route path={`${path}/midia`} render={() => <MidiaPage/>} />
                <Route path={`${path}/novo/audio`} render={() => <RecordAudioPage/>} />
                <Route path={`${path}/audio`} render={() => <PlayAudioPage/>} />
                <Route path={`${path}/novo/video`} render={() => <RecordVideoPage/>} />
                <Route path={`${path}/video`} render={() => <PlayVideoPage/>} />
                <Route path={`${path}/arquivo`} render={() => <ArquivoPage/>} />
                <Route path={`${path}/escreva`} render={() => <EscrevaPage/>} />
                <Route path={`${path}/concluir`} render={() => <ConcluirPage/>} />
            </Switch>
        </div>
    )
};

export default withRouter(HistoriaLayout)
