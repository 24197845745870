import React, {Fragment, useState} from 'react'
import VideoRecorder from 'react-video-recorder'
import Actions from './render-actions'

import { Redirect } from 'react-router-dom'

import './record-video.component.style.sass'
import { RouteContainer, useWindowDimensions } from "../utils";


export default function RecordVideoComponent(props) {
    const [recording, setRecording] = useState(false);
    const [recorded, setRecorded] = useState(false);

    const { setVideoBlob, video, video: { binary } } = props;

    const { height, width } = useWindowDimensions();
    return (
        <RouteContainer className='record-video-component'>
            { recorded !== false && binary !== null ? (
                <Fragment>
                    <Redirect to='/historia/video'/>
                </Fragment>
                ) : (
                <Fragment>
                    <div
                        style={{
                            left: -(width > 1100 ? (width - 1100)/2 : 15),
                            width,
                            height
                        }}
                        className="video-recorder-container"
                    >
                        <VideoRecorder
                            onStartRecording={() => setRecording(true)}
                            onRecordingComplete={blob => {
                                setRecording(false);
                                setRecorded(true);
                                setVideoBlob(blob)
                            }}
                            renderActions={Actions}
                        />
                    </div>
                </Fragment>
            )}
        </RouteContainer>
    )
}

