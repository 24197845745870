import React, {useState} from 'react'
import AudioVisualizerComponent from './audio-visualizer'
import AudioControlsComponent from './audio-controls/'

import './audio-recorder.component.style.sass'

export default function AudioRecorderComponent(props) {
    const [ recording, setRecording ] = useState(false);
    const { audio, recorder, stream, resetRecording, setAudioBlob } = props;

    return (
        <div className="recorder-container">
            <AudioControlsComponent
                audio={audio}
                recording={recording}
                recorder={recorder}
                setRecording={setRecording}
                resetRecording={resetRecording}
                setAudioBlob={setAudioBlob}
            />
            <AudioVisualizerComponent
                recording={recording}
                stream={stream}
            />
        </div>
    );
}
