import React from 'react'
import {MdMic, MdStop, MdRefresh} from 'react-icons/md'

import './audio-controls.component.style.sass'

export default function AudioControlsComponent(props) {
    const {audio: {binary}, setRecording, resetRecording, setAudioBlob, recorder, recording} = props;
    return (
        <div className='controls-container'>
            <div className='button-container'>
                {recording === false ?
                    binary === null ?
                        (
                            <button
                                onClick={e => {
                                    setRecording(true);
                                    setAudioBlob("recording");
                                    recorder.startRecording();
                                }}
                            >
                                <MdMic/>
                            </button>
                        ) : (
                            <button
                                onClick={e => {
                                    resetRecording();
                                    setRecording(true);
                                    setAudioBlob("recording");
                                    recorder.startRecording();
                                }}
                            >
                                <MdRefresh/>
                            </button>
                        )
                    : (
                        <button
                            onClick={e => {
                                recorder.stopRecording(() => {
                                    const blob = recorder.getBlob();
                                    setAudioBlob(blob);
                                });
                                setRecording(false);
                            }}
                        >
                            <MdStop/>
                        </button>
                    )
                }
            </div>
        </div>
    )
}
