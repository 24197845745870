import React, {Component} from 'react';
import actions from "../../actions";
import { connect } from "react-redux";
import { RegulamentoComponent } from '../../components'

class RegulamentoContainer extends Component {
    render() {
        return (
            <RegulamentoComponent toggleAgreement={ this.props.toggleAgreement } agreed={ this.props.agreed } regulamento={ this.props.regulamento } />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    toggleAgreement: () => {
        dispatch(actions.toggleAgreement());
    }
});

const mapStateToProps = state => ({
    regulamento: state.regulamento,
    agreed: state.agreed
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegulamentoContainer);
