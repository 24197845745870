import React, {Component} from 'react'
import actions from "../../actions"
import { connect } from "react-redux"
import {Redirect} from 'react-router-dom'
import { RecordVideoComponent } from '../../components'

class RecordVideoContainer extends Component {
    render() {
        return  this.props.agreed === true && this.props.user.name !== '' && this.props.user.age !== '' && this.props.user.email !== '' ?
            <RecordVideoComponent {...this.props}/>
            :    <Redirect to='/inicio'/>;
    }
}

const mapDispatchToProps = dispatch => ({
    setVideoBlob: binary => {
        dispatch(actions.setVideoBlob(binary))
    }
});

const mapStateToProps = state => ({
    video: state.video,
    agreed: state.agreed,
    user: state.user
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecordVideoContainer);
