import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'
//import actions from "../../actions";
import { connect } from "react-redux";
import { MidiaComponent } from '../../components'

class MidiaContainer extends Component {
    render() {
        return  this.props.agreed === true && this.props.user.name !== '' && this.props.user.age !== '' && this.props.user.email !== '' ?  (
            <MidiaComponent />
        ) :
            <Redirect to='/inicio'/>;
    }
}

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state => ({
    agreed: state.agreed,
    user: state.user
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MidiaContainer);
