import React from 'react'

import './logo.component.styles.sass'
import logoBig from "../../../assets/logo-medium.png";
import { SlideInContainer } from '../';

export default function LogoComponent(props) {
    return (
        <div className='logo-component'>
            <SlideInContainer>
                <img src={logoBig} alt='historias de elevador' />
            </SlideInContainer>
        </div>
    )
}
