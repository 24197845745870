import React, {Component} from 'react';
import actions from "../../actions";
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux";
import {EscrevaComponent} from '../../components'

class EscrevaContainer extends Component {
    render() {
        return this.props.agreed === true && this.props.user.name !== '' && this.props.user.age !== '' && this.props.user.email !== '' ?
            <EscrevaComponent {...this.props}/> :
            <Redirect to='/inicio'/>;
    }
}

const mapDispatchToProps = dispatch => ({
    sendFile: (file, par_id)=> {
        dispatch(actions.sendFile(file, par_id));
    },
    sendText: (text, par_id) => {
        dispatch(actions.sendText(text, par_id));
    },
    addFile: file => {
        dispatch(actions.addFile(file));
    },
    changeTextarea: value => {
        dispatch(actions.changeTextarea(value));
    }
});

const mapStateToProps = state => ({
    files: state.files,
    text: state.text,
    errorFile: state.errorFile,
    agreed: state.agreed,
    user: state.user
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EscrevaContainer);
