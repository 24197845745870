import React, {Component} from 'react';
import actions from "../../actions";
import { connect } from "react-redux";
import {Redirect} from 'react-router-dom'
import { RecordAudioComponent } from '../../components'
import RecordRTC from "recordrtc";


class RecordAudioContainer extends Component {
    state = {
        recorder: null,
        stream: null,
        duration: 0
    };
    componentDidMount() {
        navigator.mediaDevices.getUserMedia({
            audio: true,
        }).then(async (stream) => {
            this.setState({
                ...this.state,
                stream,
                recorder: RecordRTC(stream, {
                    type: 'audio',
                    mimeType: 'audio/wav',
                    timeSlice: 1000,
                    disableLogs: true,
                    ondataavailable: blob => {
                        this.setState({
                            ...this.state,
                            duration: this.state.duration + 1
                        })
                    }
                })
            });
        });
    }
    componentWillUnmount() {
       if(this.state.recorder !== null)
           this.state.recorder.destroy();
       this.state.stream.getTracks().forEach(track => track.stop());
    }

    render() {
        return  this.props.agreed === true && this.props.user.name !== '' && this.props.user.age !== '' && this.props.user.email !== '' ?
            <RecordAudioComponent
                resetRecording={() => {
                    this.state.recorder.clearRecordedData();
                    this.state.recorder.stopRecording();
                    this.setState({
                        ...this.state,
                        duration: 0
                    })
                }}
                duration={this.state.duration}
                recorder={this.state.recorder}
                stream={this.state.stream}
                audio={this.props.audio}
                setAudioBlob={ this.props.setAudioBlob }
            /> :
            <Redirect to='/inicio'/>;
    }
}

const mapDispatchToProps = dispatch => ({
    setAudioBlob: (binary) => {
        dispatch(actions.setAudioBlob(binary))
    }
});

const mapStateToProps = state => ({
    audio: state.audio,
    agreed: state.agreed,
    user: state.user
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecordAudioContainer);
