import React from 'react'
import { Link } from 'react-router-dom'

import './hero.component.style.sass'
import logoBig from '../../assets/logo-big.png'

import { RouteContainer, SlideInContainer, FadeContainer, ButtonComponent } from "../";

export default function HeroComponent(props) {
    return (
        <RouteContainer className='hero-component'  style={{height: '100%'}}>
            <SlideInContainer>
                <img src={logoBig} alt='historias de elevador' />
                <h1 className='h1-hero'>
                    Quem não tem uma história de elevador?
                    <div className='h1-hero-marker' />
                </h1>
                <div className='p-hero-container'>
                    <SlideInContainer>
                        <p className='p-hero'>
                            Você pode não perceber, mas um elevador está sempre cheio de histórias. O <b>projeto Histórias de Elevador</b>, da Atlas Schindler, vai ouvir as histórias contadas, selecionar algumas delas e compartilha-las. Já imaginou?
                        </p>
                    </SlideInContainer>
                    <SlideInContainer>
                        <p className='p-hero'>
                            Estamos ansiosos para saber a sua. Conte pra gente, gravando um vídeo ou áudio, escrevendo ou fazendo o upload de um arquivo. Não tem segredo. <b>O que vale é sua história!</b>
                        </p>
                    </SlideInContainer>
                </div>
            </SlideInContainer>
            <FadeContainer>
                <div className='cta-hero-container'>
                    <Link to='/historia/regulamento'>
                        <ButtonComponent style={{marginTop: 30}} direction='ltr' color='green' text='Iniciar' />
                    </Link>
                </div>
            </FadeContainer>
        </RouteContainer>
    )
};
