import React, {Component} from 'react';
import actions from "../../actions";
import {Redirect} from 'react-router-dom'
import { connect } from "react-redux";

import { ArquivoComponent } from '../../components'

class ArquivoContainer extends Component {
    render() {
        return  this.props.agreed === true && this.props.user.name !== '' && this.props.user.age !== '' && this.props.user.email !== '' ?
            <ArquivoComponent {...this.props}/> :
            <Redirect to='/inicio'/>;
    }
}

const mapDispatchToProps = dispatch => ({
    addFile: file => {
        dispatch(actions.addFile(file));
    },
    sendFile: (file, par_id)=> {
        dispatch(actions.sendFile(file, par_id));
    },
});

const mapStateToProps = state => ({
    files: state.files,
    errorFile: state.errorFile,
    agreed: state.agreed,
    user: state.user
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ArquivoContainer);
