import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import HomePage, { HistoriaPage } from "../../pages/"
import posed, { PoseGroup } from 'react-pose'

import Loader from 'react-loader-spinner'

import logoAtlas from '../../assets/logo-atlas.png'
import './primary.layout.styles.sass'

const RoutesContainer = posed.div({
    enter: {
        opacity: 1,
        beforeChildren: true
    },
    exit: { opacity: 0 }
});

const PrimaryLayout = (props) => {
    const { match, location, state, fetching, error } = props;
        return (
        <div className="primary-layout">
            <img src={logoAtlas} className='logo-atlas' alt='logo atlas' />
            <main style={{height: '100%'}}>
                {
                    fetching == true ? (
                        <div className='loader-container'>
                            <Loader
                                type="Oval"
                                color="#ac2726"
                                height={100}
                                width={100}
                            />
                        </div>
                    ) : (
                        <PoseGroup>
                            <RoutesContainer  style={{height: '100%'}} key={location.pathname}>
                                <Switch>
                                    <Route exact path='/:path(|inicio)' render={() => <HomePage/>} />
                                    <Route path='/historia' render={() => <HistoriaPage state={state} />} />
                                </Switch>
                            </RoutesContainer>
                        </PoseGroup>
                    )
                }
            </main>
        </div>
    )
};

export default withRouter(PrimaryLayout)
