import React from 'react'
import { Redirect } from 'react-router-dom'

import { SlideInContainer, RouteContainer, H1 } from '../'
import FormComponent from "./form/form.component";

import './dados.component.style.sass'
import { NavControlsComponent } from "../utils/";


export default function DadosComponent(props) {
    const { user, user: { name, age, email, fetched }, sendUserData } = props;

    const nextPageOnClick = function(){
        sendUserData(name, age, email)
    };

    return fetched
        ? <Redirect to='/historia/midia'/>
        : <RouteContainer className='dados-component'>
            <SlideInContainer>
                <div className='form-dados-container'>
                    <H1>
                        Por favor, informe seus dados.
                    </H1>
                    <FormComponent user={ user } changeUserData={ props.changeUserData }/>
                </div>
            </SlideInContainer>
            <div className='regulamento-nav-controls-container'>
                <NavControlsComponent nextPageOnClick={nextPageOnClick} prevPage='/historia/regulamento' nextPage={ user.name !== null && user.email !== null && user.age !== null ? '/historia/midia' : null }/>
            </div>
        </RouteContainer>
};
