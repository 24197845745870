import React from 'react'
import { RegulamentoContainer } from "../../../containers";

export default function RegulamentoPage(props) {
    return (
        <div style={ { flexGrow: 1, display: 'flex' } }   className='dados-page-container'>
            <RegulamentoContainer/>
        </div>
    )
}
