import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { HomeContainer } from "../../containers";

class HomePage extends Component {
    render() {
      return (
          <div className="home-page"  style={{height: '100%'}}>
              <HomeContainer  style={{height: '100%'}}/>
          </div>
      )
    }
}

export default withRouter(HomePage);
