import React, { } from 'react'
import { RouteContainer }  from '../'
import { AudioRecorderComponent, H1, SlideInContainer, NavControlsComponent } from '../'

import './record-audio.component.style.sass'

export default function RecordAudioComponent(props){
    const { audio, recorder, stream, duration, setAudioBlob, resetRecording } = props;

    return (
        <RouteContainer style={{display:'flex', flexGrow: 1}} className='audio-component'>
            <SlideInContainer>
                <H1 style={{padding:20}}>
                    Clique e grave um áudio nos contando a sua história!
                </H1>
                <AudioRecorderComponent
                    audio={audio}
                    stream={stream}
                    recorder={recorder}
                    duration={duration}
                    resetRecording={resetRecording}
                    setAudioBlob={setAudioBlob}
                />
            </SlideInContainer>
            <SlideInContainer>
                <NavControlsComponent prevPage='/historia/midia' nextPage={audio.binary !== null && audio.binary !== 'recording' ? '/historia/audio' : null }/>
            </SlideInContainer>
        </RouteContainer>
    )
};
