//polyfills
import 'audio-recorder-polyfill-add-importscripts'

//imports funcionais
import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from "react-router-dom"
import {Provider} from "react-redux"

// imports estruturais
import './reset.sass'
import './index.sass'
import AppContainer from "./containers"

//import store
import store from './store'

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <AppContainer/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
