import React from "react";
import {Link} from "react-router-dom";
import { SlideInContainer, ButtonComponent } from "../";

import './nav-controls-large.component.style.sass'

export default function NavControlsLargeComponent(props){
    const { prevPage, nextPage, prevPageOnClick, nextPageOnClick } = props;
    return (
        <SlideInContainer>
            <div className='nav-controls-large-container'>
                <Link to={prevPage || ''} onClick={ prevPage === null ? e => e.preventDefault() : prevPageOnClick ? prevPageOnClick : undefined }>
                    <button className='prev-button'>
                        Quero regravar
                    </button>
                </Link>
                <Link to={nextPage || ''} onClick={ nextPage === null ? e => e.preventDefault() : nextPageOnClick ? nextPageOnClick : undefined }>
                    <button className='next-button'>
                        Gostei, vamos em frente
                    </button>
                </Link>
            </div>
        </SlideInContainer>
    )
}
