import React, {Component} from 'react'
import actions from "../../actions"
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import { DadosComponent } from "../../components/"

class DadosContainer extends Component {
    render() {
        return this.props.agreed === true ? (
            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }} className="dados-container">
                <DadosComponent
                    user={ this.props.user }
                    sendUserData={ this.props.sendParData }
                    changeUserData={ this.props.changeUserData }
                />
            </div>
        ): <Redirect to='/'/> ;
    }
}

const mapDispatchToProps = dispatch => ({
    changeUserData: (key, value) => {
        dispatch(actions.changeUserData(key, value))
    },
    sendParData(user, age, email){
        dispatch(actions.sendParData(user, age, email))
    }
});

const mapStateToProps = state => ({
    agreed: state.agreed,
    user: state.user
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DadosContainer);
