import React from 'react'
import { MdVideocam, MdMic, MdEdit, MdFileUpload } from 'react-icons/md'
import { Link } from "react-router-dom";

import {RouteContainer, H1, SlideInContainer, ButtonComponent} from "../utils";
import CtaComponent from "./cta";

import './midia.component.style.sass'

export default function RegulamentoComponent(props) {
    return (
        <RouteContainer className='midia-component'>
            <SlideInContainer>
                <H1 style={{
                    padding: '20px 0 0'
                }}>
                    Como você quer nos contar a sua história de elevador?
                </H1>
            </SlideInContainer>
            <SlideInContainer className='midia-options-container'>
                <div className="midia-options-col">
                    <CtaComponent to='/historia/novo/video' icon={ <MdVideocam/> } text='Grave um vídeo'/>
                </div>
                <div className="midia-options-col">
                    <CtaComponent to='/historia/novo/audio' icon={ <MdMic/> } text='Grave um audio'/>
                </div>
                <div className="midia-options-col">
                    <CtaComponent to='/historia/escreva' icon={ <MdEdit/> } text='Mande um texto'/>
                </div>
                <div className="midia-options-col">
                    <CtaComponent to='/historia/arquivo' icon={ <MdFileUpload/> } text='Faça um upload'/>
                </div>
            </SlideInContainer>
            <Link to='/historia/dados' style={{marginTop: 30}}>
                <ButtonComponent direction='rtl' color='red' text='Voltar' />
            </Link>
        </RouteContainer>
    )
}
