import React from 'react'
import './file-upload.component.style.sass'

export default function FileUploadComponent(props) {
    const { handleFile, files, errorFile } = props;
    return (
        <label>
            <div className="file-upload-container" style={{ marginTop: 30 }}>
                <input type='file' name='file' onChange={ handleFile } />
                <div className='input-component'>
                    { errorFile === true ?
                        ("Arquivo não suportado") :
                        files[0] === undefined ? (
                            "Adicionar Arquivo"
                        ): (
                            <>
                            {files[0].name.length > 10 ? files[0].name.substring(0,9) + '...' : files[0].name}. Clique para trocar
                            </>
                        )
                    }
                </div>
                <p className='supported-formats'>
                    Formatos suportados: .mp3, .txt, .doc. jpeg, .png
                </p>
            </div>
        </label>
    )
}
